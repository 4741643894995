import { ActiveCompanyService } from './../../shared/services/active-company.service';
// This project is developed by [Derwing Medina](https://derwing-portfolio.netlify.app/) for IPS Nueva Popayan 
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { AuthGuard, redirectUnauthorizedTo } from '@angular/fire/auth-guard';


@Injectable({
  providedIn: 'root',
})
export class CustomAuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
    private authGuard: AuthGuard,
    private activeCompanyService: ActiveCompanyService
  ) {}


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> { // Activamos el loading
    let roles: string[] = [];

    const storedUser = sessionStorage.getItem('currentUser'); // Obtener roles desde sessionStorage
    if (storedUser) {
      const userSessionData = JSON.parse(storedUser);
      roles = userSessionData.roles;
    }

    this.activeCompanyService.loading = true;
    return this.authGuard.canActivate(route, state)

    .pipe(
      map((user) => {
        const isAuthenticated = !!user; // Check if user is authenticate
        if (!isAuthenticated) {
          this.authService.setRedirectUrl(this.router.routerState.snapshot.url);
          setTimeout(() => {
            this.router.navigate(['/login']); // redirect user to login if not
          }, 200);

        }
        this.activeCompanyService.loading = false;
        return isAuthenticated; // allow or denied
      }),
      tap((isAuthenticated) => {
        if (isAuthenticated) {
          this.authService.setRedirectUrl(''); // clean last url
        }
        // if(roles.includes('company_user')){
        //   // disable loading
        // this.activeCompanyService.loading = false;
        //   return true;
        // } else {
        //   // disable loading
        //   this.router.navigateByUrl('/403'); // Redirigir a la página de error
        //   return false;
        // }
        this.activeCompanyService.loading = false;
        
      })
    );
  }


}
