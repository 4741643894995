import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { ActiveCompanyService } from '../../shared/services/active-company.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyAdminGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router, private activeCompanyService: ActiveCompanyService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
      const user = this.authService.getUserInfo(); // obtener el usuario autenticado
    let roles: string[] = []; 

    const storedUser = sessionStorage.getItem('currentUser'); // Obtener los roles del usuario autenticado
    if (storedUser) {
      const userSessionData = JSON.parse(storedUser);
      roles = userSessionData.roles; 
    }

    // // Verificar si el usuario tiene el rol de 'company_admin'
    // if(user && user.email == 'derwing.medina@gmail.com'){
    //   return true;
    // } 
    if (roles && roles.includes('company_admin')) {
      return true; // Permitir acceso
    } else {
      this.activeCompanyService.loading = false;
      this.router.navigateByUrl('/403');
      return false;
    }
  }
}
