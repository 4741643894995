import { Component, OnDestroy, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from '../../auth/services/auth.service';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.css'
})
export class SidebarComponent implements OnDestroy{
  currentRoles: string[] = [];
  private authSubscription: Subscription;
  isLoading: boolean = true;


  constructor(private authService: AuthService){
    this.isLoading = true;
    this.authSubscription = this.authService.getAuthStatus().subscribe(user => {
      if (user) {
        setTimeout(() => {
          this.checkMenuOptions();
        }, 2000);
      } else {
        this.currentRoles = []; // Si el usuario no está autenticado, limpiar los roles
      }
    });
  }
  

checkMenuOptions(): void {
  const storedUser = sessionStorage.getItem('currentUser');
  if (storedUser) {
    const userSessionData = JSON.parse(storedUser);
    this.currentRoles = userSessionData.roles;
  } else {
    this.currentRoles = [];
  }
  setTimeout(() => {
    this.isLoading = false;
  }, 3000);
}

ngOnDestroy(): void {
  // Cancelar la suscripción cuando el componente se destruya
  this.authSubscription.unsubscribe();
}
}
