// src/environments/environment.ts
import { initializeApp } from 'firebase/app';
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';


export const environment = {
  production: false,
    firebaseConfig: {
        apiKey: "AIzaSyDo0sbxmOIdDwWA76DNv-FaPP9erC9jw9o",
        authDomain: "consentimientos-informados.firebaseapp.com",
        databaseURL: "https://consentimientos-informados-default-rtdb.firebaseio.com",
        projectId: "consentimientos-informados",
        storageBucket: "consentimientos-informados.appspot.com",
        messagingSenderId: "529747423299",
        appId: "1:529747423299:web:78c3e08ccd0ff83c3024f6",
        measurementId: "G-VVWCZSSGF4"
  },
  
}
const app = initializeApp(environment.firebaseConfig);
const auth = getAuth(app);

//TODO: Esto genera persistencia del usuario en el localStorage pero no estoy seguro si me es útil.
// setPersistence(auth, browserLocalPersistence)
// .then(() => {
//   // Existing and future Auth states are now persisted across page reloads.
// })
// .catch((error) => {
//   console.error('Error setting persistence:', error);
// });