// Este proyecto es creado por [Derwing Medina](https://derwing-portfolio.netlify.app/) para IPS Nueva Popayan 
import { Component, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { NavbarComponent } from './layout/navbar/navbar.component';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoadingComponent } from './shared/components/loading/loading.component';
import { CommonModule } from '@angular/common';
import { ActiveCompanyService } from './shared/services/active-company.service';
import { FooterComponent } from './layout/footer/footer/footer.component';
import { BreadcrumbComponent } from "./shared/breadcrumb/breadcrumb.component";
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { filter, Subscription, map } from 'rxjs';
import { DeviceDetectionService } from './shared/services/device-detection.service';
import { FormVisibilityService } from './shared/services/form-visibility.service';
import { AuthService } from './auth/services/auth.service';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, NavbarComponent, SidebarComponent, LoadingComponent, FooterComponent, BreadcrumbComponent, BreadcrumbModule, ButtonModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
})
export class AppComponent implements OnInit {
  title = 'consentimientos';
  showBreadcrumb: boolean = true;
  isMobile: boolean = false;
  isConsentForm: boolean = false;
  loggedUser: boolean = false;
  showOpenPatientLogin: boolean = false;
  private routerSubscription: Subscription;
  

  constructor(public activeCompanyService: ActiveCompanyService,
    private deviceDetectionService: DeviceDetectionService,
    private formVisibilityService: FormVisibilityService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ){
    this.loggedUser = this.authService.isLoggedIn();
    // Suscribirse a los eventos de navegación
 this.routerSubscription = this.router.events.pipe(
  filter((event): event is NavigationEnd => event instanceof NavigationEnd),
  map((event: NavigationEnd) => event)
).subscribe((event) => {
  // Aquí puedes manejar el cambio de ruta  
  if(event.urlAfterRedirects != '/dashboard'){
    this.showOpenPatientLogin = true;
  }
});
  }

  ngOnInit() {
    this.isMobile = this.deviceDetectionService.isMobileDevice();
    

    // Suscripción al estado del formulario de consentimiento
    this.formVisibilityService.isForm$.subscribe(isForm => {
      this.isConsentForm = isForm;
    });

    if(sessionStorage.getItem('active_patient')){
      this.loggedUser = true;
      this.showOpenPatientLogin = true;
    }
}

ngOnDestroy() {
if (this.routerSubscription) {
  this.routerSubscription.unsubscribe();
}

}

  openPatientLogin(){
    this.showOpenPatientLogin = true;
  }

}


/**
* functions description
*  Esta función/método es usado para ir al top de la página cuando se cambia la ruta y evitar dejar al usuario al final del scroll si cambia de componente
* @param - sin parametros.
* @returns  sin retornos.
*/