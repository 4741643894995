import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { onAuthStateChanged } from 'firebase/auth';

export const administrativeStaffGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const router = inject(Router);

  return new Promise((resolve) => {
    onAuthStateChanged(authService.getAuth(), async (user) => {
      if (user) {
        const token = await user.getIdTokenResult();
        const roles = token.claims['roles'] as string[] || [];
        if (roles.includes('super_admin') || 
            roles.includes('company_admin') || 
            roles.includes('company_administrative_staff')) {
          resolve(true);
        } else {
          router.navigate(['/403']);
          resolve(false);
        }
      } else {
        router.navigate(['/403']);
        resolve(false);
      }
    });
  });
};
