<!-- This project is developed by [Derwing Medina](https://derwing-portfolio.netlify.app/) for IPS Nueva Popayan -->
<nav class="navbar">
    <div class="navbar-left">
        <!-- Logo -->
        @if (companyLogoUrl) {
        <a routerLink="/">
            <img [src]="companyLogoUrl" alt="Logo" class="navbar-logo">
        </a>
        }

    </div>

    <div class="navbar-right">
        <!-- Search sections -->
        <!-- <div class="navbar-search">
            <i class="pi pi-search"></i>
        </div> -->

        <!-- User menu -->
        <div class="navbar-user">
            <!-- @if (hasAvatar) {
            <p-avatar icon="pi pi-user" styleClass="mr-2" size="normal" class="mr-1" [style]="{ color: '#2a1261' }"
                shape="circle" />
            } -->

            <div class="dropdown" (click)="toggleMenu()">
                <i class="pi pi-cog dropdown-toggle" style="font-size: 1.5rem"></i>
                <!-- Dropdown menu -->
                @if (isDropdownOpen) {
                <ul class="dropdown-menu">
                    <li (click)="redirectMenu('/admin-console', $event)"
                        *ngIf="user && (user?.roles?.includes('company_admin') || user?.roles?.includes('super_admin') || user?.roles?.includes('company_medical_staff') || user?.roles?.includes('company_administrative_staff'))">
                        <i class="pi pi-spin pi-cog mr-1"></i> Consola Administrativa
                    </li>
                    <li (click)="login()" *ngIf="!user">
                        <i class="pi pi-sign-in mr-1"></i> Entrada usuario de la empresa
                    </li>
                    <li (click)="logout()" *ngIf="user">
                        <i class="pi pi-sign-out mr-1"></i> Salir
                    </li>
                </ul>
                }
            </div>


        </div>
    </div>
</nav>