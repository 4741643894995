<!-- This project is developed by [Derwing Medina](https://derwing-portfolio.netlify.app/) for IPS Nueva Popayan -->
<div class="sidebar">
    <ul>
        <li><a [routerLink]="(currentRoles.includes('company_admin') || 
            currentRoles.includes('company_administrative_staff') || 
            currentRoles.includes('company_doctor')) ? 
            '/admin-console' : '/dashboard'" routerLinkActive="router-link-active'">
                <i class="pi pi-home"></i>
            </a></li>
        <!-- @defer{
        @if((currentRoles.includes('company_admin') || currentRoles.includes('super_admin') && !isLoading)){
        <li><a [routerLink]="'/admin-console'" routerLinkActive="router-link-active'"><i class="pi pi-cog"></i></a>
        </li>
        }
        } -->
        <!-- <li><i class="pi pi-home"></i></li>
        <li><i class="pi pi-home"></i></li>
        <li><i class="pi pi-home"></i></li>
        <li><i class="pi pi-home"></i></li> -->

    </ul>
</div>