import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { ActiveCompanyService } from '../../shared/services/active-company.service';
import { Auth } from '@angular/fire/auth';
import { onAuthStateChanged } from 'firebase/auth';


@Injectable({
  providedIn: 'root'
})
export class superAdminGuard implements CanActivate {
  constructor(private router: Router, private activeCompanyService: ActiveCompanyService, private auth: Auth) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    let roles: string[] = []; 

    const storedUser = sessionStorage.getItem('currentUser'); // Obtener los roles del usuario autenticado
    if (storedUser) {
      const userSessionData = JSON.parse(storedUser);
      roles = userSessionData.roles; 
    }

    if (roles && roles.includes('super_admin')) {
      return true; // Permitir acceso
    } else {
      this.activeCompanyService.loading = false;
      this.router.navigateByUrl('/403');
      return false;
    }
  }
}

