import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterModule } from '@angular/router';
import { distinctUntilChanged, filter, map } from 'rxjs';
import { MenuItem } from 'primeng/api'; // Importación necesaria para los ítems del breadcrumb
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { BreadcrumbService } from '../breadcrumb.service';

@Component({
  selector: 'app-breadcrumb',
  standalone: true,
  imports: [CommonModule, RouterModule, BreadcrumbModule],
  templateUrl: './breadcrumb.component.html',
  styleUrl: './breadcrumb.component.css'
})

export class BreadcrumbComponent implements OnInit {
  breadcrumbItems: MenuItem[] = [];
  home: MenuItem | undefined;
  showBreadcrumb = true;

  constructor(private router: Router, private activatedRoute: ActivatedRoute,
    private breadcrumbService: BreadcrumbService
  ) {}

  ngOnInit(): void {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd), // Filtrar solo eventos de finalización de navegación
        map(() => this.createBreadcrumbs(this.activatedRoute.root)) // Crear los breadcrumbs dinámicos
      )
      .subscribe(breadcrumbs => {
        this.breadcrumbItems = breadcrumbs; // Asignar los breadcrumbs al componente
      });
      this.home = { icon: 'pi pi-home', routerLink: '/' };
      this.breadcrumbService.isBreadcrumbVisible().subscribe(isVisible => {
        this.showBreadcrumb = isVisible;
      });
  }

  createBreadcrumbs(route: ActivatedRoute, url: string = '', breadcrumbs: MenuItem[] = []): MenuItem[] {
    const children: ActivatedRoute[] = route.children;
  
    if (children.length === 0) {
      return breadcrumbs;
    }
  
    for (const child of children) {
      const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');
      if (routeURL !== '') {
        url += `/${routeURL}`;
      }
  
      if (child.snapshot.data['breadcrumb']) {
        breadcrumbs.push({
          label: child.snapshot.data['breadcrumb'],
          routerLink: url // 'routerLink'
        });
      }
  
      return this.createBreadcrumbs(child, url, breadcrumbs); // Continuar creando breadcrumbs recursivamente
    }
    
    return breadcrumbs;
  }
}  