<main class="main">
  <div class="main-layout">
    <nav class="top-nav">
      <app-navbar></app-navbar>
    </nav>

    <aside class="sidebar">
      <app-sidebar></app-sidebar>
    </aside>

    <main class="content">
      @if(showBreadcrumb && loggedUser){
      <!-- TODO: Revisar que el breadcrumb funcione correctamente antes de volver a habilitarlo. -->
      <!-- <app-breadcrumb></app-breadcrumb> -->
      }

      <app-loading *ngIf="activeCompanyService.loading"></app-loading>
      <router-outlet *ngIf="!activeCompanyService.loading"></router-outlet>
    </main>
  </div>
</main>

@if(showBreadcrumb && loggedUser){
<footer class="bottom-nav">
  <app-footer *ngIf="activeCompanyService.showFooter && !isMobile && !isConsentForm"></app-footer>
</footer>
}