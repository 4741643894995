import { ManageConsentsService } from './../../shared/services/manage-consents.service';
import { CommonModule } from '@angular/common';
import { AfterContentChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { AuthService } from '../../auth/services/auth.service';
import { ActiveCompanyService } from '../../shared/services/active-company.service';
import { Company } from '../../shared/Models/company.model';
import { CustomUser } from '../../shared/interfaces/custom-user.interface';

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [CommonModule, AvatarModule, AvatarGroupModule, RouterModule],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.css'
})
export class NavbarComponent implements OnInit, AfterContentChecked {
  hasAvatar: boolean = true;
  isDropdownOpen = false;
  companyLogoUrl: string = '';
  user: { email: string, roles: string[], token: string } | null = null;


  constructor(
    private router: Router,
    private authService: AuthService,
    private activeCompanyService: ActiveCompanyService) {
    
  }

  ngOnInit() {
    this.activeCompanyService.fetchActiveCompany().subscribe(
      (companyData: Company) => {
        this.companyLogoUrl = companyData.logoUrl;
      },
      (error) => {
        console.error('Error al cargar los datos de la empresa:', error);
      }
    );
    
  }
  
  ngAfterContentChecked(){
    const storedUser = sessionStorage.getItem('currentUser');
    if (storedUser) {
      const userSessionData = JSON.parse(storedUser);
      this.user = {
        email: userSessionData.email,
        roles: userSessionData.roles,
        token: userSessionData.token
      };
    }
  }
  
  toggleMenu() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }
  
  
  redirectMenu(route: string, event: MouseEvent) {
    // Prevents the click event from being propagated to the parent element otherwise it will not close the dropdown
    event.stopPropagation();
    
    // close the dropDown  
    this.isDropdownOpen = false;

    // Redirect to especific route
    this.router.navigateByUrl(route);
  }

  login(){
    sessionStorage.removeItem('active_patient');
    this.router.navigateByUrl('/login');
  }
  
  logout() {
    sessionStorage.removeItem('currentUser');
    this.user = null;
    this.authService.logout();
  }


}