import { ManageConsentsService } from './../../shared/services/manage-consents.service';
import { CommonModule } from '@angular/common';
import { AfterContentChecked, ChangeDetectorRef, Component, OnInit, OnDestroy } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { AuthService } from '../../auth/services/auth.service';
import { ActiveCompanyService } from '../../shared/services/active-company.service';
import { Company } from '../../shared/Models/company.model';
import { CustomUser } from '../../shared/interfaces/custom-user.interface';
import { Subscription } from 'rxjs';
import { User } from '@firebase/auth';

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [CommonModule, AvatarModule, AvatarGroupModule, RouterModule],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.css'
})
export class NavbarComponent implements OnInit, OnDestroy {
  hasAvatar: boolean = true;
  isDropdownOpen = false;
  companyLogoUrl: string = '';

  user: any | null = null;
  roles: string[] = [];

  private userSubscription: Subscription;

  constructor(
    private router: Router,
    private authService: AuthService,
    private activeCompanyService: ActiveCompanyService) {
      // Suscribirse al estado de autenticación con roles
      this.userSubscription = this.authService.getUserWithRoles().subscribe(user => {
        this.user = user;
      });
  }

  ngOnInit() {
    this.activeCompanyService.fetchActiveCompany().subscribe(
      (companyData: Company) => {
        this.companyLogoUrl = companyData.logoUrl;
      },
      (error) => {
        console.error('Error al cargar los datos de la empresa:', error);
      }
    );
    
  }
  
  
  
  toggleMenu() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }
  
  
  redirectMenu(route: string, event: MouseEvent) {
    // Prevents the click event from being propagated to the parent element otherwise it will not close the dropdown
    event.stopPropagation();
    
    // close the dropDown  
    this.isDropdownOpen = false;

    // Redirect to especific route
    this.router.navigateByUrl(route);
  }

  login(){
    sessionStorage.removeItem('active_patient');
    this.router.navigateByUrl('/login');
  }
  
  logout() {
    sessionStorage.removeItem('currentUser');
    this.user = null;
    this.authService.logout();
  }

  // Métodos de ayuda para verificar roles
  hasRole(role: string): boolean {
    return this.authService.hasRole(role);
  }

  hasAnyRole(roles: string[]): boolean {
    return this.authService.hasAnyRole(roles);
  }

  ngOnDestroy(): void {
    // Des-suscribirse para evitar fugas de memoria
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

}