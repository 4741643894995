import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

export const viewConsentGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const router = inject(Router);
  
  const storedUser = sessionStorage.getItem('currentUser');
  if (storedUser) {
      const userSessionData = JSON.parse(storedUser);
      const roles = userSessionData.roles as string[];
      
      // Permitir acceso si tiene cualquiera de estos roles
      if (roles.includes('company_admin') || 
          roles.includes('company_administrative_staff') || 
          roles.includes('company_medical_staff') || 
          roles.includes('super_admin')) {
          return true;
      }
  }
  
  router.navigateByUrl('/403');
  return false;
};
